<template>
  <div class="tile">
    <h1 class="hd">{{ msg }}</h1>
  </div>
</template>

<script>
// @ is an alias to /src
//import { gsap } from "@/gsap/esm/gsap-core";
//import { CSSRulePlugin } from "@/gsap/esm/CSSRulePlugin";
//import { Draggable } from "@/gsap/esm/Draggable";

//gsap.registerPlugin(CSSRulePlugin);


import { gsap } from "gsap";
import { ExpoScaleEase, RoughEase, SlowMo } from "gsap/EasePack";
import { CSSRulePlugin } from "gsap/CSSRulePlugin";
import { Draggable } from "gsap/Draggable";
import { EaselPlugin } from "gsap/EaselPlugin";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import { PixiPlugin } from "gsap/PixiPlugin";
import { TextPlugin } from "gsap/TextPlugin";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { CustomEase } from "gsap/CustomEase";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
import { Flip } from "gsap/Flip";
import { GSDevTools } from "gsap/GSDevTools";
import { InertiaPlugin } from "gsap/InertiaPlugin";
import { MorphSVGPlugin } from "gsap/MorphSVGPlugin";
import { MotionPathHelper } from "gsap/MotionPathHelper";
import { Physics2DPlugin } from "gsap/Physics2DPlugin";
import { PhysicsPropsPlugin } from "gsap/PhysicsPropsPlugin";
import { ScrambleTextPlugin } from "gsap/ScrambleTextPlugin";
import { SplitText } from "gsap/SplitText";
import { CustomBounce } from "gsap/CustomBounce";
import { CustomWiggle } from "gsap/CustomWiggle";

gsap.registerPlugin(CSSRulePlugin, Draggable, EaselPlugin, MotionPathPlugin, PixiPlugin, TextPlugin, ScrollToPlugin, ScrollTrigger, CustomEase, DrawSVGPlugin, Flip, GSDevTools, InertiaPlugin, MorphSVGPlugin, MotionPathHelper, Physics2DPlugin, PhysicsPropsPlugin, ScrambleTextPlugin, SplitText, CustomBounce, CustomWiggle, ExpoScaleEase, RoughEase, SlowMo);



export default {
  name: "Tile",
  props: {
    msg: String,
  },
  mounted() {
    gsap.to(".tile", {rotation:360, backgroundColor:"red", duration: 4});

    Draggable.create(".tile", {
      onClick: function(){ gsap.to(this.target, {rotation:0, backgroundColor:"blue", duration: 4});},
      });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.tile{
  user-select: none;
  background-color: blue;
  color:white;
  border: 1px solid white;
  margin: auto;
  padding: 20px;
  width: 400px;
}


</style>
